import { DataViewAction, DataViewState, LevelResource, SetDataSourceActionPack, DataViewReducerElements } from './types'
import { AccountDto } from '../../dtos/account.dto'
import { DomainDto } from '../../dtos/domain'
import { Dispatch, useCallback, useEffect, useReducer } from 'react'
import { buildInitialState, DataViewStateInitializerProps } from './context'

export const dataViewReducer = <T extends {}>(state: DataViewState<T>, action: DataViewAction<T>): DataViewState<T> => {
    switch (action.type) {
        case 'RESET':
            return action.data
        case 'SET_LEVEL_RESOURCE_LOADING':
            return setLevelResourceLoading(state)
        case 'SET_LEVEL':
            return setLevel(state, action.data)
        case 'SET_DATA_SOURCE_LOADING':
            return setDataSourceLoading(state)
        case 'SET_DATA_SOURCE':
            return setDataSource(state, action.data)
        case 'SET_SELECTED_IDS':
            return setSelectedIds(state, action.data)
        case 'SET_FILTER_VALUE':
            return setFilterValue(state, action.data.key, action.data.value)
        case 'SET_REFRESH_TIMESTAMP_UPDATE_HANDLER':
            return setRefreshTimestampUpdateHandler(state, action.data)
        case 'SET_REFRESH_REQUESTED':
            return setRefreshRequested(state)
        case 'SET_AUTO_REFRESH_REQUESTED':
            return setRefreshRequested(state, true)
        case 'SET_TABLE_STATE':
            return setTableState(state, action.data)
        case 'SET_CURRENT_PAGE':
            return setCurrentPage(state, action.data)
        default:
            return state
    }
}

export const useRequestDataSourceReloadDispatcher = (dispatch: Dispatch<DataViewAction>) => {
    const dispatcher = useCallback(() => {
        dispatch({ type: 'SET_REFRESH_REQUESTED' })
    }, [])

    return dispatcher
}

export const useDataViewReducer = <T extends {}>(props: DataViewStateInitializerProps): DataViewReducerElements<T> => {
    const [context, dispatch] = useReducer(dataViewReducer<T>, buildInitialState(props))

    const requestDataSourceReload = useRequestDataSourceReloadDispatcher(dispatch)

    useEffect(() => {
        if (
            props.id !== context.id ||
            props.level !== context.level ||
            props.levelResourceId !== context.levelResourceId
        ) {
            dispatch({ type: 'RESET', data: buildInitialState(props) })
        }
    }, [props.id, props.level, props.levelResourceId])

    return [context, dispatch, requestDataSourceReload]
}

/**
 * Individual reducer functions should not be exported for testing.
 * Unit tests should use the dataViewReducer method + and associated
 * action type and data pack.
 */

function setLevelResourceLoading(state: DataViewState, loading: boolean = true): DataViewState {
    return { ...state, levelResourceLoading: loading }
}

function setLevel(state: DataViewState, data: LevelResource): DataViewState {
    const update: DataViewState = { ...state }

    update.levelResource = data

    if (update.level === 'org') {
        update.org = data as AccountDto
    } else {
        update.domain = data as DomainDto
    }

    return setLevelResourceLoading(update, false)
}

function setDataSourceLoading(state: DataViewState, loading: boolean = true): DataViewState {
    return {
        ...state,
        dataSourceLoading: loading,
        refreshing: state.refreshRequested || state.autoRefreshRequested,
    }
}

function setDataSource(state: DataViewState, { data, links }: SetDataSourceActionPack): DataViewState {
    /**
     * New data source assigment should ensure the refresh timer
     * is updated to offset the next auto-run from now
     */
    state.refreshTimestampUpdateHandler()

    return setDataSourceLoading(
        {
            ...state,
            dataSource: data,
            paginationLinks: links,

            /**
             * New data source assigment should resolve any open refresh requests
             */
            refreshRequested: false,
            autoRefreshRequested: false,
        },
        false,
    )
}

function setSelectedIds(state: DataViewState, ids: DataViewState['selectedIds']): DataViewState {
    return { ...state, selectedIds: ids }
}

function setFilterValue(state: DataViewState, key: string, value: any): DataViewState {
    const filterValues = { ...state.filterValues }

    if (value === undefined) {
        delete filterValues[key]
    } else {
        filterValues[key] = value
    }

    return setCurrentPage({ ...state, filterValues }, 1)
}

function setRefreshTimestampUpdateHandler(
    state: DataViewState,
    handler: DataViewState['refreshTimestampUpdateHandler'],
): DataViewState {
    return { ...state, refreshTimestampUpdateHandler: handler }
}

function setRefreshRequested(state: DataViewState, isAutoRequest: boolean = false): DataViewState {
    const update = { ...state }

    if (!state.refreshing) {
        if (isAutoRequest && !state.refreshRequested) {
            update.autoRefreshRequested = true
        } else if (!isAutoRequest && !state.autoRefreshRequested) {
            update.refreshRequested = true
        }
    }

    return update
}

function setTableState(state: DataViewState, data: DataViewState['table']): DataViewState {
    return { ...state, table: data }
}

function setCurrentPage(state: DataViewState, page: number): DataViewState {
    return setTableState(state, {
        ...state.table,
        pagination: {
            ...state.table.pagination,
            current: page,
        },
    })
}
