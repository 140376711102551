import { useEffect, useState } from 'react'
import { useDataViewContext } from './context'

type UseSnapshotOptions<T extends {} = any> = {
    includeFilters?: boolean
    includeTable?: boolean
    augments?: T
}

/**
 * Snapshots serves as a state view for loaders and effects.
 * The base snapshot includes level and levelResourceId
 * as any changes to either of these two values should
 * trigger an effect invocation or reload of data.
 *
 * Reserved properties are: l (level), f (filters), and tb (table)
 *
 * @param {object}  [options]                       Snapshot creation options
 * @param {boolean} [options.includeFilters=false]  Include filter values
 * @param {boolean} [options.includeTable=false]    Include table pagination and sorting info
 * @param {object}  [options.augments]              Additional properties to add to the snapshot
 */
export const useDataViewSnapshot = <T extends {} = any>(options: UseSnapshotOptions<T> = {}): [string, string] => {
    const [state] = useDataViewContext()
    const [prevSnapshot, setPrevSnapshot] = useState('')

    const augments = options.augments ?? {}
    const snapshotParts: any = { ...augments, l: [state.level, state.levelResourceId] }
    if (options.includeFilters) {
        snapshotParts.f = state.filterValues
    }
    if (options.includeTable) {
        snapshotParts.tb = state.table
    }
    const snapshot = btoa(JSON.stringify(snapshotParts))

    useEffect(() => {
        if (snapshot !== prevSnapshot) {
            setPrevSnapshot(snapshot)
        }
    }, [snapshot])

    return [snapshot, prevSnapshot]
}
