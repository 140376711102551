import React, { useCallback, useEffect, useState } from 'react'
import { DataViewFilterProps as FilterProps } from './types'
import { Input } from 'antd'
import { BetterSelect } from '../better-select/better-select'
import { SearchProps } from 'antd/lib/input'
import { OnBetterSelectChangeFunction, OnBetterSelectCloseFunction } from '../better-select/interfaces'
import { useDataViewContext } from './context'
import dotProp from 'dot-prop'
import classnames from 'classnames'
import deepEqual from 'react-fast-compare'

const LOCAL_STORAGE_VALUE_KEY = 'v'
const LOCAL_STORAGE_MIGRATED_KEY = 'mx'

const getStickyContainer = () => JSON.parse(localStorage.getItem('sw.dv') ?? '{}')
const setStickyContainer = (value: any) => localStorage.setItem('sw.dv', JSON.stringify(value))

/**
 * Retrieves localStorage path + key combination value.
 *
 * If a migrationHydrator is passed an attempt to migrate
 * legacy data is performed. Hydrated date is then saved
 * over any existing data and the key will be marked as
 * hydrated to prevent the need for future hydration.
 *
 * @param prefix - The dot notation string path to the key container. eg: `d.{id}.{view}.f.{name}`
 * @param key - The filter storage key. eg: 'v' for value or 'mx' for migrated
 * @param migrationHydrator - Hydration method for pulling from legacy storage location
 */
const getStickyValue = (prefix: string, key: string, migrationHydrator?: () => any): any => {
    const container = getStickyContainer()

    const path = `${prefix}.${key}`
    const migrated = dotProp.get(container, `${prefix}.${LOCAL_STORAGE_MIGRATED_KEY}`, false)

    let value: any
    if (key !== LOCAL_STORAGE_MIGRATED_KEY && migrationHydrator && !migrated) {
        value = migrationHydrator()
        if (value) {
            setStickyValue(prefix, key, value)
            setStickyValue(prefix, LOCAL_STORAGE_MIGRATED_KEY, true)
        }
    } else {
        value = dotProp.get(container, path)
    }

    return value
}

/**
 * Updates localStorage with the desired path + key + value combination.
 * If an undefined value is passed the property is deleted from storage.
 *
 * @param prefix - The dot notation string path to the key container. eg: `d.{id}.{view}.f.{name}`
 * @param key - The filter storage key. eg: 'v' for value or 'mx' for migrated
 * @param value - The filter value
 */
const setStickyValue = (prefix: string, key: string, value: any) => {
    const container = getStickyContainer()

    if (Array.isArray(value)) {
        value.sort(Intl.Collator().compare)
    }

    const isUndefined = value === undefined
    const isEmptyArray = !isUndefined && Array.isArray(value) && !value.length

    if (isUndefined || isEmptyArray) {
        dotProp.delete(container, `${prefix}.${key}`)
    } else {
        dotProp.set(container, `${prefix}.${key}`, value)
    }

    setStickyContainer(container)
}

const getSorted = (values: any[]) => {
    values = [...values]
    values.sort(Intl.Collator().compare)
    return values
}

type DataViewFilterProps = FilterProps & { name: string; currentValue: any }

export const DataViewFilter = (props: DataViewFilterProps) => {
    const [state, dispatch] = useDataViewContext()
    const { type, name, sticky, migrationHydrator } = props

    // ----- Parse default filter value -----
    let defaultValue = state.filterValues[name]
    if (type !== 'custom') {
        // Standard components should give precedence to an explicitly passed value
        defaultValue = props.componentProps?.value ?? defaultValue
    }
    if (Array.isArray(defaultValue)) {
        defaultValue = getSorted(defaultValue)
    }
    // -----

    const [searchLiveTypeTimeout, setSearchLiveTypeTimeout] = useState<any>()
    const [localValue, _setLocalValue] = useState<any>(defaultValue)
    const currValue = state.filterValues[name]

    // ----- Storage path -----
    let usingCustomStoragePath = false
    let localStorageDataViewPath: string = `${state.level[0]}.${state.levelResourceId}.${state.id}`

    if (typeof sticky === 'object') {
        if ('agnostic' in sticky && sticky.agnostic) {
            localStorageDataViewPath = `${state.level[0]}.${state.levelResourceId}.shared`
        } else if ('storageKey' in sticky && sticky.storageKey.trim()) {
            usingCustomStoragePath = true
            localStorageDataViewPath = `custom.${sticky.storageKey.trim()}`
        }
    }

    let localStoragePath = `${localStorageDataViewPath}.f.${name}`.toLowerCase()
    if (usingCustomStoragePath) {
        localStoragePath = `${localStorageDataViewPath}.f`.toLowerCase()
    }
    // -----

    /**
     * Wraps `_setLocalValue()` ensuring proper formatting and
     * value persistence for sticky filters.
     *
     * The formatted value is returned for use downstream.
     */
    const setLocalValue = useCallback(
        (value: any) => {
            if (Array.isArray(value)) {
                value = getSorted(value)
            }

            if (sticky) {
                setStickyValue(localStoragePath, LOCAL_STORAGE_VALUE_KEY, value)
            }

            _setLocalValue(value)

            return value
        },
        [localStoragePath, sticky],
    )

    /**
     * Builds the `'SET_FILTER_VALUE'` `DataViewAction`
     * package and invokes `dispatch()`
     */
    const onFilterChange = useCallback(
        (nextValue: any) => {
            if (!deepEqual(nextValue, currValue)) {
                dispatch({ type: 'SET_FILTER_VALUE', data: { key: name, value: nextValue } })
            }
        },
        [name, currValue],
    )

    const onSelectClose: OnBetterSelectCloseFunction = useCallback(
        (nextValue) => {
            onFilterChange(setLocalValue(nextValue))
        },
        [name, currValue, localValue],
    )

    const onSelectChange: OnBetterSelectChangeFunction = useCallback(
        (nextValue) => {
            setLocalValue(nextValue)
        },
        [name, currValue, localValue],
    )

    const onSearchClick: Required<SearchProps>['onSearch'] = useCallback(
        (term, _ev) => {
            clearTimeout(searchLiveTypeTimeout)
            onFilterChange(setLocalValue(term || undefined))
        },
        [name, currValue, localValue, searchLiveTypeTimeout],
    )

    const onSearchChange: Required<SearchProps>['onChange'] = useCallback(
        (ev) => {
            let term = ev.target.value

            // Immediately update local value for search input
            term = setLocalValue(term || undefined)

            clearTimeout(searchLiveTypeTimeout)
            setSearchLiveTypeTimeout(
                setTimeout(() => {
                    onFilterChange(term)
                }, 320),
            )
        },
        [name, currValue, localValue, searchLiveTypeTimeout],
    )

    // ----- Initial sticky value retrieval and population -----
    useEffect(() => {
        if (type !== 'search' && sticky) {
            let stickyValue = getStickyValue(localStoragePath, LOCAL_STORAGE_VALUE_KEY, migrationHydrator)
            if (stickyValue) {
                onFilterChange(setLocalValue(stickyValue))
            } else if (!currValue) {
                if (type === 'select') {
                    if (defaultValue) {
                        // When default value is provided and updated
                        // after the initial render we should ensure
                        // that it is honored as long as no current
                        // selection or sticky value is available
                        onFilterChange(setLocalValue(defaultValue))
                    } else if (props.componentProps?.options.length) {
                        // When no selection has been made yet,
                        // no sticky value is available, and no default
                        // value was provided we should fall back to ALL
                        // available option values as the default value
                        const nextValue: any[] = []
                        props.componentProps.options.forEach((opt) => {
                            if ('value' in opt) {
                                nextValue.push(opt.value)
                            } else {
                                nextValue.push(...opt.options.map((child) => child.value))
                            }
                        })

                        onFilterChange(setLocalValue(nextValue))
                    }
                }
            }
        }
    }, [localStoragePath, sticky, !!migrationHydrator])
    // -----

    const classNames: Array<string | undefined> = ['data-view-filter', type, `filter-${name}`]
    if (type !== 'custom') {
        classNames.push(props.componentProps?.className)
    }

    const sharedProps = {
        className: classnames(classNames),
        value: localValue,
        disabled: props.disabled,
    }

    switch (props.type) {
        case 'search':
            return (
                <Input.Search
                    {...props.componentProps}
                    {...sharedProps}
                    defaultValue={defaultValue}
                    enterButton={true}
                    onSearch={onSearchClick}
                    onChange={onSearchChange}
                />
            )

        case 'select':
            return (
                <BetterSelect
                    {...props.componentProps}
                    {...sharedProps}
                    defaultValue={defaultValue}
                    options={props.componentProps?.options ?? []}
                    mode="multiple"
                    onChange={onSelectChange}
                    onClose={onSelectClose}
                />
            )

        case 'custom':
            return props.render(onFilterChange)

        default:
            return null
    }
}
