import { createContext, Dispatch, useContext } from 'react'
import { DataViewAction, DataViewReducerElements, DataViewState } from './types'
import { useRequestDataSourceReloadDispatcher } from './reducer'
import { PlatformLevel } from '../../types/platform-level'
import { RefreshTimeout } from '../../enums/refresh-timeout'
import { SortOrder } from 'antd/lib/table/interface'

export type DataViewStateInitializerProps = {
    id: string
    level: PlatformLevel
    levelResourceId: number
    defaultPageSize?: number
    defaultSorting?: {
        columnKey: string
        order: SortOrder
    }
    defaultFilters?: Record<string, any>
    refreshTimeout?: RefreshTimeout
}

export const buildInitialState = <T extends {} = any>(props: DataViewStateInitializerProps): DataViewState<T> => {
    const filterValues: DataViewState<T>['filterValues'] = {}

    if (props.defaultFilters) {
        Object.keys(props.defaultFilters)?.forEach((key) => {
            filterValues[key] = props.defaultFilters![key]
        })
    }

    return {
        id: props.id,
        instanceId: btoa(`${props.id}|${props.level}|${props.levelResourceId}`),

        level: props.level,
        levelResource: null!,
        org: null!,
        domain: null!,
        levelResourceId: props.levelResourceId,
        levelResourceLoading: false,

        loading: false,

        dataSource: [],
        dataSourceLoading: false,

        table: {
            pagination: {
                current: 1,
                pageSize: props.defaultPageSize ?? 15,
            },
            sorter: props.defaultSorting ?? undefined,
        },

        selectedIds: [],
        filterValues,

        refreshing: false,
        refreshEnabled: true,
        refreshTimeout: props.refreshTimeout ?? RefreshTimeout.FIVE_MINUTES,
        refreshRequested: false,
        autoRefreshRequested: false,
        refreshTimestampUpdateHandler() {},
    }
}

type DataViewContext<T extends {} = any> = { state: DataViewState<T>; dispatch: Dispatch<DataViewAction> }
export const DataViewContext = createContext<DataViewContext>(null!)

export const useDataViewContext = <T extends {}>(): DataViewReducerElements<T> => {
    const { state, dispatch } = useContext(DataViewContext) as DataViewContext<T>
    return [state, dispatch, useRequestDataSourceReloadDispatcher(dispatch)]
}
