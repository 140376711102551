import React from 'react'
import { DataViewHeaderProps } from './types'
import classnames from 'classnames'
import { DataViewFilters } from './data-view-filters'
import { DataViewRefresh } from './data-view-refresh'

export const DataViewHeader = (props: DataViewHeaderProps) => {
    const { title } = props

    return (
        <div className={classnames('data-view-header')}>
            <div
                className={classnames('data-view-title', {
                    hidden: !title,
                })}
            >
                <span>{title}</span>
            </div>

            <DataViewFilters {...props} />
            <DataViewRefresh {...props} />
        </div>
    )
}
