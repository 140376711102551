import { DataViewTableProps } from './types'
import classnames from 'classnames'
import { AntdTableEmptyPlaceholder } from '../aqe/antd-table-empty-placeholder/antd-table-empty-placeholder'
import { LoadingOutlined } from '@ant-design/icons'
import SwSimpleLinkPagination from '../sw-simple-link-pagination/sw-simple-link-pagination'
import { Table } from 'antd'
import React, { useCallback, useEffect } from 'react'
import { useDataViewContext } from './context'
import { TablePaginationConfig } from 'antd/lib/table'
import { Key, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import { useDataViewSnapshot } from './helpers'

export const DataViewTable = <T extends {}>(props: DataViewTableProps<T>) => {
    const [state, dispatch] = useDataViewContext()
    const [snapshot, prevSnapshot] = useDataViewSnapshot({
        includeFilters: true,
        includeTable: true,
    })

    const levelResourceId = (state.level === 'org' ? state.org?.id : state.domain?.id) ?? 0
    const showTableLoadingAnimation =
        (state.loading || state.levelResourceLoading || state.dataSourceLoading) && !state.autoRefreshRequested

    const onTableChange = useCallback(
        (
            pagination: TablePaginationConfig,
            _: Record<string, Key[] | null>,
            sorter: SorterResult<T> | SorterResult<T>[],
            extra: TableCurrentDataSource<T>,
        ) => {
            dispatch({ type: 'SET_TABLE_STATE', data: { pagination, sorter } })
            props.onChange?.(pagination, sorter, extra)
        },
        [state.level, levelResourceId],
    )

    const onPaginationChange = useCallback(
        (_ev: React.MouseEvent<HTMLElement>, _link: string, config: Partial<TablePaginationConfig>) => {
            dispatch({ type: 'SET_CURRENT_PAGE', data: config.current ?? state.table.pagination.current! })
        },
        [state.level, levelResourceId, state.table.pagination.current],
    )

    const setSelectedRowIds = useCallback(
        (ids: Key[]) => {
            props.onRowSelectionChanged?.(ids)
            dispatch({ type: 'SET_SELECTED_IDS', data: ids })
        },
        [state.level, levelResourceId],
    )

    const extractRowKey = useCallback(
        (row: T) => {
            switch (typeof props.rowKey) {
                case 'string':
                    return row[props.rowKey]
                case 'function':
                    return props.rowKey(row)
                default:
                    return 'id' in row ? row.id : undefined
            }
        },
        [typeof props.rowKey],
    )

    const visibleIds = state.dataSource.map((data: T) => extractRowKey(data))
    const selectedVisibleIds = state.selectedIds.filter((id) => visibleIds.includes(id))

    useEffect(() => {
        if (snapshot !== prevSnapshot) {
            setSelectedRowIds([])
        }
    }, [snapshot, prevSnapshot])

    return (
        <Table<T>
            locale={{
                emptyText: !props.noData ? undefined : (
                    <AntdTableEmptyPlaceholder text={props.noData.text} icon={props.noData.icon} />
                ),
                ...(props.locale ?? {}),
            }}
            {...props}
            className={classnames('data-view-table', props.className, {
                'hide-title': !props.title,
                'hide-pagination': props.hidePagination,
                'hide-actions': props.hideRowActions,
            })}
            rowClassName={(record, index, indent) => {
                const rowClassName =
                    typeof props.rowClassName === 'function'
                        ? props.rowClassName(record, index, indent)
                        : props.rowClassName

                return classnames('data-view-row', rowClassName)
            }}
            size="small"
            loading={
                showTableLoadingAnimation && {
                    indicator: <LoadingOutlined spin={true} />,
                }
            }
            footer={(currentTableData) => (
                <SwSimpleLinkPagination
                    currentTableData={currentTableData}
                    links={state.paginationLinks}
                    onPrev={onPaginationChange}
                    onNext={onPaginationChange}
                />
            )}
            dataSource={state.dataSource as T[]}
            pagination={false}
            onChange={onTableChange}
            rowSelection={
                !props.rowSelectionEnabled
                    ? undefined
                    : {
                          selectedRowKeys: selectedVisibleIds,
                          onSelectAll: (selectAll: boolean, _selectedRows: T[], changeRows: T[]) => {
                              const changedIds: Key[] = changeRows.map((s) => extractRowKey(s))

                              if (selectAll) {
                                  const nextIds = [...state.selectedIds, ...changedIds]
                                  setSelectedRowIds(nextIds)
                              } else {
                                  const nextIds = state.selectedIds.filter((id) => !changedIds.includes(id))
                                  setSelectedRowIds(nextIds)
                              }
                          },
                          onChange: (_selectedKeys: React.Key[], selectedRows: T[]) => {
                              const nextSelectedIds: Key[] = selectedRows.map((s) => extractRowKey(s))
                              const newIds = nextSelectedIds
                                  .filter((id) => !state.selectedIds.includes(id))
                                  .map((id) => parseInt(id.toString(), 10))

                              const nextIds = [
                                  ...state.selectedIds.filter(
                                      (id) => nextSelectedIds.includes(id) || !visibleIds.includes(id),
                                  ),
                                  ...newIds,
                              ]

                              setSelectedRowIds(nextIds)
                          },
                      }
            }
        >
            {props.children}
        </Table>
    )
}
