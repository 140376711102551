import React from 'react'
import { AppMessageColumnViewProps, AppMessageDataRecord } from './types'
import { useDataViewContext } from '../data-view/context'
import { AppMessageScheduleWithFullMessage } from '@pushly/models/lib/structs/app-messages/app-message-schedule-with-full-message'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { TableRowEntityDisplay } from '../table-row-entity-display/table-row-entity-display'
import { NoTranslate } from '../no-translate/no-translate'
import { AppMessageStatusBadge } from '../badges/app-message-status-badge'
import { AudienceBadge } from '../badges/audience-badge'
import { NativeIosBadge } from '../badges/native-ios-badge'
import { NativeAndroidBadge } from '../badges/native-android-badge'
import { buildPrimaryLinkUrl } from './helpers'
import { useService } from '@pushly/aqe/lib/hooks/index'
import { AppService } from '../../services/index'
import { StatusName } from '@pushly/aquarium-utils/lib/enums'
import { AppMessageScheduleViewable } from '../app-message-builder/model-extensions'

export const AppMessageDisplayColumnView = (props: AppMessageColumnViewProps<AppMessageScheduleViewable>) => {
    const [state] = useDataViewContext<AppMessageDataRecord>()
    const { data } = props

    const appSvc = useService(AppService)

    const name = data.message.name
    const channels: DeliveryChannel[] | undefined = data.message.template?.channels
    const status = data.computedStatus ?? data.status

    return (
        <TableRowEntityDisplay
            title={
                <NoTranslate>
                    <a href={buildPrimaryLinkUrl(state.level, data, appSvc.routeWithin)}>{name}</a>
                </NoTranslate>
            }
            status={
                <AppMessageStatusBadge status={props.nativeDisabled ? StatusName.DISABLED : status} expanded={true} />
            }
            audience={
                <span className="audience-type">
                    <AudienceBadge source={data} />
                </span>
            }
            badges={
                <>
                    {channels?.includes(DeliveryChannel.NATIVE_IOS) && <NativeIosBadge />}
                    {channels?.includes(DeliveryChannel.NATIVE_ANDROID) && <NativeAndroidBadge />}
                </>
            }
        />
    )
}
