import React from 'react'
import {
    ChannelFieldMeta,
    ExtendedRuleBuilderField,
    IRuleBuilderField,
    IRuleBuilderSelectField,
} from '../rule-builder/rule-builder'
import { baseOperators, semanticOperators } from '../rule-builder/operators'
import InputKeywordClicked from '../rule-builder-v2/inputs/input-keyword-clicked/input-keyword-clicked'
import { InputNotificationClicks } from '../rule-builder/input-notification-clicks'
import { InputNotificationIdClicked } from '../rule-builder/input-notification-id-clicked'
import { InputPageTagVisited } from '../rule-builder/input-page-tag-visited'
import { InputPageVisited } from '../rule-builder/input-page-visited'
import { FrequencyWithWindow } from '../rule-builder-v2/inputs/frequency-with-window/frequency-with-window'
import { getDomainCurrencyCode, getDomainCurrencySymbol } from '../../_utils/domain'
import { segmentCountryOptions } from '../../enums/segment-countries'
import { IDomainSegmentBuilderContext, SegmentBuilderContext } from './segment-builder-context'
import { segmentContinentOptions } from '../../enums/segment-continents'
import {
    ensureSelectMetaDisplayNameTransformIn,
    ensureSelectMetaDisplayNameTransformOut,
} from '../rule-builder-v2/utils'
import aqe from '@pushly/aqe'
import { DomainPageTag } from '../../dtos/domain'
import { OrgSegmentModel } from '../../models/segments/org-segment.model'
import { SegmentModel } from '../../models/segments/segment.model'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

export const APP_VERSION_PROPERTY = 'profile.app_version'

const webChannelSelected = (segment: OrgSegmentModel | SegmentModel): boolean => {
    return segment.getChannels()?.includes(DeliveryChannel.WEB) || false
}

const nativeChannelSelected = (
    segment: OrgSegmentModel | SegmentModel,
    channel?: DeliveryChannel.NATIVE_IOS | DeliveryChannel.NATIVE_ANDROID,
): boolean => {
    const channels = segment.getChannels()
    if (channel) {
        return channels.includes(channel)
    }

    return channels?.includes(DeliveryChannel.NATIVE_IOS) || channels?.includes(DeliveryChannel.NATIVE_ANDROID) || false
}

export const getSegmentBuilderFields = (
    context: SegmentBuilderContext,
): (IRuleBuilderField | ExtendedRuleBuilderField<any>)[] => [
    {
        property: 'profile.subscriber_age',
        display: 'Behavior: Allow Age',
        type: 'number',
        metric: 'days',
    },
    {
        property: 'profile.subscribed_date',
        display: 'Behavior: Allow Date',
        type: 'date',
    },
    {
        condition: (ctx) => ctx.level === 'domain',
        property: 'profile.prompt_id',
        display: 'Behavior: Allow Prompt',
        type: 'prompt',
        operators: [baseOperators.bool_eq, baseOperators.bool_neq],
    },
    {
        condition: (ctx) => webChannelSelected(ctx.segment),
        property: 'profile.subscribed_referrer',
        display: 'Behavior: Allow Referrer',
        type: 'string',
    },
    {
        property: 'profile.subscribed_url',
        display: 'Behavior: Allow URL',
        type: 'string',
    },
    {
        condition: (ctx) => ctx.level === 'domain',
        property: 'campaigns',
        display: 'Behavior: Campaign Status',
        type: 'campaign',
    },
    {
        property: 'tag',
        display: 'Behavior: Keyword',
        type: 'string',
        useArrayContains: true,
        suggestions: context.keywords,
        operators: [baseOperators.a_contains, baseOperators.a_not_contains, baseOperators.contains],
    },
    {
        condition: (ctx) => !ctx.isDrawerMode,
        property: 'behaviors.tag_clicked_audience',
        display: 'Behavior: Keyword Clicked',
        type: 'custom',
        defaultOperator: baseOperators.gte,
        render: (
            mode: 'edit' | 'display',
            field: IRuleBuilderField,
            value: any,
            operator: string | undefined,
            onChange: (value: any) => any,
            _setRef: (el: any) => any,
        ) => {
            return (
                <InputKeywordClicked
                    mode={mode}
                    field={field}
                    operator={operator}
                    value={value}
                    onChange={onChange}
                    loading={!context.keywordsLoaded}
                    keywords={context.keywords}
                />
            )
        },
    },
    {
        property: 'profile.last_seen',
        display: 'Behavior: Last on Site / App',
        type: 'date',
    },
    {
        property: 'behaviors.notification_clicks',
        display: 'Behavior: Notification Click Count',
        type: 'custom',
        defaultOperator: baseOperators.gte,
        render: (
            mode: 'edit' | 'display',
            field: IRuleBuilderField,
            value: any,
            operator: string | undefined,
            onChange: (value: any) => any,
            setRef: (el: any) => any,
        ) => {
            return (
                <InputNotificationClicks
                    ref={setRef}
                    mode={mode}
                    field={field}
                    value={value}
                    operator={operator}
                    onChange={onChange}
                />
            )
        },
    },
    {
        condition: (ctx) => ctx.level === 'domain',
        property: 'behaviors.notification_ids_clicked',
        display: 'Behavior: Notification Clicked',
        type: 'custom',
        defaultOperator: baseOperators.a_contains,
        useArrayContains: true,
        render: (
            mode: 'edit' | 'display',
            field: IRuleBuilderField,
            value: any,
            operator: string | undefined,
            onChange: (value: any) => any,
            setRef: (el: any) => any,
        ) => {
            return (
                <InputNotificationIdClicked
                    ref={setRef}
                    mode={mode}
                    field={field}
                    value={value}
                    operator={operator}
                    onChange={onChange}
                />
            )
        },
    },
    {
        condition: (ctx) => !ctx.isDrawerMode,
        property: 'behaviors.page_tag_audience',
        display: 'Behavior: Page / Screen Tag',
        type: 'custom',
        defaultOperator: baseOperators.gte,
        render: (
            mode: 'edit' | 'display',
            field: IRuleBuilderField,
            value: any,
            operator: string | undefined,
            onChange: (value: any) => any,
            setRef: (el: any) => any,
        ) => {
            return (
                <InputPageTagVisited
                    ref={setRef}
                    mode={mode}
                    field={field}
                    value={value}
                    operator={operator}
                    onChange={onChange}
                    typeaheadURL={
                        context.level === 'domain'
                            ? `${aqe.defaults.publicApiDomain}/v3/domains/${context.domain.id}/page-tags`
                            : `${aqe.defaults.publicApiDomain}/v3/accounts/${context.org.id}/page-tags`
                    }
                    typeaheadTransform={(data: DomainPageTag[]) => data.map((pt) => pt.name)}
                />
            )
        },
    },
    {
        condition: (ctx) => !ctx.isDrawerMode,
        property: 'behaviors.page_audience',
        display: 'Behavior: Page / Screen URL',
        type: 'custom',
        defaultOperator: baseOperators.gte,
        render: (
            mode: 'edit' | 'display',
            field: IRuleBuilderField,
            value: any,
            operator: string | undefined,
            onChange: (value: any) => any,
            setRef: (el: any) => any,
        ) => {
            return (
                <InputPageVisited
                    ref={setRef}
                    mode={mode}
                    field={field}
                    value={value}
                    operator={operator}
                    onChange={onChange}
                />
            )
        },
    },
    {
        condition: (ctx) => ctx.canShowSubscriberPreferences,
        property: 'preferences',
        display: 'Behavior: Preference',
        type: 'preferences',
    },
    {
        condition: (ctx) => webChannelSelected(ctx.segment),
        property: 'profile.navigator',
        display: 'Device: Browser',
        type: 'select',
        options: [
            { name: 'Chrome', value: 'Chrome' },
            { name: 'Edge', value: 'Edge' },
            { name: 'Firefox', value: 'Firefox' },
            { name: 'Opera', value: 'Opera' },
            { name: 'Safari', value: 'Safari' },
        ],
        operators: [baseOperators.eq, baseOperators.neq],
    },
    {
        property: '_id',
        display: 'Device: Subscriber ID / Push ID',
        type: 'string',
        operators: [baseOperators.eq, baseOperators.neq],
    },
    {
        property: 'profile.external_id',
        display: 'Device: External ID',
        type: 'string',
        operators: [baseOperators.eq, baseOperators.neq, baseOperators.exists, baseOperators.not_exists],
    },
    {
        condition: (ctx) => nativeChannelSelected(ctx.segment),
        property: 'app_identifier',
        display: 'Device: App Identifier (iOS Bundle / Android Package)',
        type: 'string',
        operators: [baseOperators.eq, baseOperators.neq],
    },
    {
        property: 'behaviors.purchase_count',
        display: 'Behavior: Purchase Count',
        type: 'custom',
        defaultOperator: baseOperators.gte,
        render: (
            mode: 'edit' | 'display',
            field: IRuleBuilderField,
            value: any,
            operator: string | undefined,
            onChange: (value: any) => any,
            setRef: (el: any) => any,
        ) => {
            return (
                <FrequencyWithWindow
                    ref={setRef}
                    mode={mode}
                    field={field}
                    value={value}
                    operator={operator}
                    onChange={onChange}
                    maxValue={999}
                    disableLifetime={true}
                    valueLabel={'time(s)'}
                />
            )
        },
    },
    {
        condition: (ctx) => ctx.level === 'domain',
        property: 'behaviors.purchase_amount',
        display: 'Behavior: Purchase Amount',
        type: 'custom',
        defaultOperator: baseOperators.gte,
        render: (
            mode: 'edit' | 'display',
            field: IRuleBuilderField,
            value: any,
            operator: string | undefined,
            onChange: (value: any) => any,
            setRef: (el: any) => any,
        ) => {
            const currency = getDomainCurrencyCode((context as IDomainSegmentBuilderContext).domain)

            return (
                <FrequencyWithWindow
                    ref={setRef}
                    mode={mode}
                    field={field}
                    value={value}
                    operator={operator}
                    onChange={onChange}
                    maxValue={1_000_000}
                    disableLifetime={true}
                    valueLabel={null}
                    valuePrefix={getDomainCurrencySymbol((context as IDomainSegmentBuilderContext).domain)}
                    formatValueDisplay={(v) =>
                        Intl.NumberFormat('en-us', {
                            style: 'currency',
                            currency,
                        }).format(v)
                    }
                />
            )
        },
    },
    {
        condition: (ctx) => nativeChannelSelected(ctx.segment, DeliveryChannel.NATIVE_ANDROID),
        property: `${APP_VERSION_PROPERTY}.${DeliveryChannel.NATIVE_ANDROID.toLowerCase()}`,
        display: 'Device: App Version (Android)',
        type: 'app_version',
        operators: semanticOperators,
        data: {
            channel: DeliveryChannel.NATIVE_ANDROID,
        },
    } satisfies ExtendedRuleBuilderField<ChannelFieldMeta>,
    {
        condition: (ctx) => nativeChannelSelected(ctx.segment, DeliveryChannel.NATIVE_IOS),
        property: `${APP_VERSION_PROPERTY}.${DeliveryChannel.NATIVE_IOS.toLowerCase()}`,
        display: 'Device: App Version (Apple)',
        type: 'app_version',
        operators: semanticOperators,
        data: {
            channel: DeliveryChannel.NATIVE_IOS,
        },
    } satisfies ExtendedRuleBuilderField<ChannelFieldMeta>,
    {
        property: 'profile.placement',
        display: 'Device: Type',
        type: 'device-placement',
        operators: [baseOperators.bool_eq, baseOperators.bool_neq],
    },
    {
        property: 'profile.device_os',
        display: 'Device: Operating System',
        type: 'select',
        options: [
            { name: 'Android', value: 'Android' },
            { name: 'Mac', value: 'Mac' },
            { name: 'Linux', value: 'Linux' },
            { name: 'Windows', value: 'Windows' },
        ],
        operators: [baseOperators.contains, baseOperators.not_contains],
    },
    {
        property: 'profile.city',
        display: 'Location: City',
        type: 'string',
    },
    {
        property: 'profile.continent_code',
        display: 'Location: Continent',
        type: 'select',
        options: segmentContinentOptions,
        displayValueProp: 'name',
        operators: [baseOperators.eq, baseOperators.neq],
        ruleTransformIn: ensureSelectMetaDisplayNameTransformIn,
        ruleTransformOut: ensureSelectMetaDisplayNameTransformOut,
    } as IRuleBuilderSelectField,
    {
        property: 'profile.country_code',
        display: 'Location: Country',
        type: 'select',
        options: segmentCountryOptions,
        enableOptionsSearch: true,
        displayValueProp: 'name',
        extra: {
            optionFilterProp: 'label',
        },
        operators: [baseOperators.eq, baseOperators.neq],
        ruleTransformIn: ensureSelectMetaDisplayNameTransformIn,
        ruleTransformOut: ensureSelectMetaDisplayNameTransformOut,
    } as IRuleBuilderSelectField,
    {
        property: 'profile.location',
        display: 'Location: Custom Selection',
        type: 'custom-location',
    },
    {
        property: 'profile.postal_code',
        display: 'Location: Postal Code',
        type: 'string',
    },
    {
        property: 'profile.province',
        display: 'Location: State / Province',
        type: 'string',
    },
    {
        property: 'divider',
        type: 'divider',
    },
]
