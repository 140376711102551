import * as React from 'react'
import * as changeCase from 'change-case'
import { TextEncoder, TextDecoder } from 'text-encoding'
import axios from 'axios'
import { notification } from 'antd'
import { DomainDto } from '../dtos/domain'
import * as numeral from 'numeral'
import * as querystring from 'query-string'
import * as moment from 'moment'
import aqe from '@pushly/aqe'
import { FlagModel } from '../models/domain/flag.model'
import FlagList from '../structs/flags-list'
import { handleResponseErrorMessage } from './response-error-utils'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { Moment } from 'moment'

if (!(window as any).TextEncoder) (window as any).TextEncoder = TextEncoder
if (!(window as any).TextDecoder) (window as any).TextDecoder = TextDecoder

export interface IProxyEvent {
    target: {
        value: string
    }
}

export const noop = () => {}

export const preventBubbling = (event: MouseEvent | any): void => {
    if (event) {
        event.stopPropagation()
    }
}

export const preventDefault = (event: MouseEvent | any): void => {
    if (event) {
        event.preventDefault()
    }
}

export const preventAll = (event: MouseEvent | any): void => {
    preventBubbling(event)
    preventDefault(event)
}

export const isProxyEvent = (e: any): e is IProxyEvent => {
    return e.hasOwnProperty('target')
}

export const pause = (pauseDelay?: number, unit?: 'ms' | 's' | 'm'): Promise<void> => {
    // default 42 ms
    pauseDelay = pauseDelay === undefined ? 42 : pauseDelay

    switch (unit) {
        case 'm':
            pauseDelay = pauseDelay * 1000 * 60
            break
        case 's':
            pauseDelay = pauseDelay * 1000
            break
        case 'ms':
        default:
            break
    }

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve()
        }, pauseDelay)
    })
}

export const delay = (fn: Function, seconds: number, container?: any): Promise<void> => {
    return new Promise((resolve, reject) => {
        const timer = setTimeout(async () => {
            try {
                const result = await fn()
                resolve(result)
            } catch (error) {
                reject(error)
            }
        }, seconds * 1000)

        if (container) {
            container.delayTimer = timer
        }
    })
}

export const getWithDefault = (value: any, defaultValue: any = undefined): any => {
    if (value === undefined || value === null) {
        return defaultValue
    }

    if (typeof value === 'string' && value.trim() === '') {
        return defaultValue
    }

    return value
}

function hexToRgb(hex: string): any {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null
}

export const hexColorFromString = (str: string): string => {
    return intToRGB(hashCode((str as any).padEnd(30, base64Encode(str))))
}

interface IRgbValues {
    r: number
    g: number
    b: number
}
export const rgbColorFromString = (str: string): IRgbValues => {
    return hexToRgb(hexColorFromString(str))
}

export const base64Encode = (str: string): string => {
    const bytes = new TextEncoder().encode(str)
    return btoa(bytes as any)
}

export const hashCode = (str: string): number => {
    // java String#hashCode
    let hash = 0

    for (let i = 0; i < str.length; i++) {
        // tslint:disable-next-line:no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }

    return hash
}

export const intToRGB = (int: number): string => {
    // tslint:disable-next-line:no-bitwise
    const code = (int & 0x00ffffff).toString(16).toUpperCase()

    return '00000'.substring(0, 6 - code.length) + code
}

export const alterRGB = (rgb: IRgbValues, change: number, lighten = true): IRgbValues => {
    Object.keys(rgb).forEach((rgbKey) => {
        let v = rgb[rgbKey]
        // tslint:disable-next-line:no-bitwise no-conditional-assignment
        rgb[rgbKey] = (lighten ? (v += change) : (v -= change)) < 0 ? 0 : v > 255 ? 255 : v | 0
    })

    return rgb
}

export const numberWithCommas = (input: number, format?: string): string => {
    return numeral(input).format(format ?? 'O,O')
}

export const numberWithPercent = (input: number): string => {
    return numeral(input).format('O.OO%')
}

export const isBoolean = (value: any): value is Boolean => {
    return typeof value === 'boolean' && (value === true || value === false)
}

export const arrayContains = (array: any[], needle: any): boolean => {
    return array.indexOf(needle) !== -1
}

export const arrayUniqueObjects = <T extends any, K extends keyof T>(array: T[], key: K): T[] => {
    const uq: T[] = []

    for (const item of array) {
        const match = uq.find((i) => {
            if (typeof i[key] === 'function') {
                return (i[key] as unknown as Function)() === (item[key] as unknown as Function)()
            } else {
                return i[key] === item[key]
            }
        })

        if (match) {
            continue
        }
        uq.push(item)
    }

    return uq
}

export const arrayUnique = (array: any[]): any[] => {
    return array.filter((item: any, idx: number, arr: any[]) => {
        return arr.indexOf(item) === idx
    })
}

export const arrayObjectIndex = (array: any[], needleKey: string, needleValue: any) => {
    let index = -1

    array.some((item: any, idx: number) => {
        if (item[needleKey] === needleValue) {
            index = idx
            return true
        }

        return false
    })

    return index
}

export const arrayRemove = (arr: any[], entryFinder: any): any[] => {
    let entryIndex: number | undefined

    if (typeof entryFinder === 'function') {
        entryIndex = arr.findIndex(entryFinder)
    } else {
        entryIndex = arr.indexOf(entryFinder)
    }

    if (entryIndex !== undefined && entryIndex !== -1) {
        arr.splice(entryIndex, 1)
    }

    return arr
}

type SearchFunction = (obj: any) => any
type ReduceFunction = (previousValue: number, currentValue: any, currentIndex?: number, array?: any[]) => number
export const objectMapReduce = (
    input: Record<string, any>[],
    map: string | SearchFunction,
    reduce: ReduceFunction = (v, n) => (n += v),
    seed: number = 0,
) => {
    return input.map((item) => (typeof map === 'string' ? item[map] : map(item))).reduce(reduce, seed)
}

export const deepmerge = (...args: any[]) => {
    if (args.length === 0) {
        return {}
    }

    const target = args.shift()

    args.forEach((source) => {
        source = source || {}

        for (const prop in source) {
            if (source.hasOwnProperty(prop)) {
                if (typeof source[prop] === 'object') {
                    target[prop] = deepmerge({}, target[prop], source[prop])
                } else {
                    target[prop] = source[prop]
                }
            }
        }
    })

    return target
}

export const minutesToSeconds = (minutes: number): number => {
    return 60 * minutes
}

export const hoursToSeconds = (hours: number): number => {
    return minutesToSeconds(60) * hours
}

export const daysToSeconds = (days: number): number => {
    return hoursToSeconds(24) * days
}

export const validateSession = async () => {
    const response = await axios.get(`${aqe.defaults.publicApiDomain}/auth/validate-token`)
    return response.data.data
}

export const setCurrentDomainSession = (domain: DomainDto) => {
    window.sessionStorage.setItem('pufferfish.domainSession', JSON.stringify(domain))
}

export const getCurrentDomainSession = () => {
    const domainData = window.sessionStorage.getItem('pufferfish.domainSession')
    if (!domainData) return
    return DomainDto.fromApiResponse(JSON.parse(domainData))
}

export const getCurrentDomainStorage = () => {
    const rawData = window.localStorage.getItem('pufferfish')
    if (!rawData) return
    const jsonData = JSON.parse(rawData)
    if (!jsonData.currentDomainJsonData) return
    return DomainDto.fromApiResponse(JSON.parse(jsonData.currentDomainJsonData))
}

export const fetchUserPermissions = async (userId: number): Promise<{ ok: boolean; data: any[] }> => {
    let ok = false
    let permissions: any[] = []

    try {
        const request = await axios.get(`${aqe.defaults.publicApiDomain}/users/${userId}/permissions`)

        ok = true
        permissions = request.data.data
    } catch (error) {
        handleResponseErrorMessage(error)
    }

    return { ok, data: permissions }
}

export const fetchAllDomains = async (options?: any): Promise<any> => {
    options = options || {}
    if (!options.pagination) options.pagination = 0
    options = querystring.stringify(options || {})

    const axiosDomains = await axios.get(`${aqe.defaults.publicApiDomain}/v3/domains?${options}`)
    if (axiosDomains.data.status === 'error') {
        throw axiosDomains.data.message
    }

    const { data: domains, ...meta } = axiosDomains.data
    const domainDtos: DomainDto[] = domains.map((domain: any) => DomainDto.fromApiResponse(domain))

    return {
        data: domainDtos,
        meta,
    }
}

export const fetchDomain = async (domainId: any): Promise<DomainDto> => {
    const axiosDomains = await axios.get(`${aqe.defaults.publicApiDomain}/domains/${domainId}`)
    const domain: any = axiosDomains.data
    return DomainDto.fromApiResponse(domain.data)
}

export const fetchFlags = async () => {
    const axiosFlags = await axios.get(`${aqe.defaults.publicApiDomain}/flags?pagination=0`)
    const flags = axiosFlags.data.data
    return FlagList.from(flags, FlagModel.build)
}

export const isFunction = (input: any): input is Function => {
    return input && {}.toString.call(input) === '[object Function]'
}

export const escapeRegExp = (regexp: string): string => {
    return regexp.replace(/[-[\]\/{}()*+?.\\^$|]/g, '\\$&')
}

export const numericSort = (a: any, b: any): number => {
    try {
        a = parseInt(a ?? 0, 10)
    } catch (_) {}
    try {
        b = parseInt(b ?? 0, 10)
    } catch (_) {}

    if (a < b) return -1
    if (a > b) return 1
    return 0
}

export const simpleSort = (a: any, b: any): number => {
    a = String(a).toLowerCase()
    b = String(b).toLowerCase()

    if (a < b) return -1
    if (a > b) return 1
    return 0
}

export function backfillStats(
    stats: any[] = [],
    breakdown: string,
    blank: any,
    channels: DeliveryChannel[] = [],
    dateConfig: {
        dateCol?: string
        startDate: string | Date | Moment
        endDate?: string | Date | Moment
    },
): any[] {
    let backfilledStats: any[] = []
    let { startDate, endDate, ...config } = dateConfig
    const { dateCol } = config

    if (startDate) {
        startDate = moment(startDate)
            .startOf(breakdown as any)
            .format('YYYY-MM-DD')
    }
    if (endDate) {
        endDate = moment(endDate)
            .startOf(breakdown as any)
            .format('YYYY-MM-DD')
    }

    if (!!stats) {
        let firstStat = stats[0]
        const dateColumn = dateCol || (!!firstStat && 'send_date' in firstStat ? 'send_date' : 'event_date')

        if (!firstStat || (startDate && !!firstStat && firstStat[dateColumn] !== startDate)) {
            firstStat = {
                ...blank,
                [dateColumn]: startDate,
            }
        }

        // channel only present for channel by breakdown currently
        if (channels.length) {
            for (const channel of channels) {
                if (channel) {
                    const initialChannelStat = stats.find(
                        (stat) => firstStat[dateColumn] === stat[dateColumn] && stat.channel === channel,
                    ) ?? { ...blank, channel, [dateColumn]: firstStat[dateColumn] }

                    if (initialChannelStat) backfilledStats.push(initialChannelStat)
                }
            }
        } else {
            backfilledStats.push(firstStat)
        }

        const lastStat = stats[stats.length - 1] || firstStat
        const statDateCap = moment(`${endDate || lastStat[dateColumn]} 00:00:00`)

        let previousStat: any = firstStat

        const backfillAndStepThrough = (nextEventDate: string, channel?: string) => {
            let nextStat = stats.find((s: any) => {
                if (channel) {
                    return s[dateColumn] === nextEventDate && s.channel === channel
                }
                return s[dateColumn] === nextEventDate
            })

            if (!nextStat) {
                nextStat = {
                    ...blank,
                    [dateColumn]: nextEventDate,
                }

                if (channel) nextStat.channel = channel
            }

            backfilledStats.push(nextStat)
            previousStat = nextStat
        }

        let idx = 0
        const cap = 10000
        while (true) {
            if (idx++ >= cap) break

            const nextStatDate: moment.Moment = moment(`${previousStat[dateColumn]} 00:00:00`).add(1 as any, breakdown)
            const nextEventDate = nextStatDate.format('YYYY-MM-DD')

            if (nextStatDate.isAfter(statDateCap)) {
                break
            }

            if (channels.length) {
                for (const channel of channels) {
                    if (channel) {
                        backfillAndStepThrough(nextEventDate, channel)
                    }
                }
            } else {
                backfillAndStepThrough(nextEventDate)
            }
        }
    }

    return backfilledStats
}

export const simpleNotification = (
    method: string,
    description: React.ReactNode,
    duration?: number,
    key?: string,
    clear: boolean = false,
) => {
    if (clear) {
        notification.destroy()
    }

    if (description !== null && typeof description === 'object') {
        if ('message' in description) description = (description as any).message
        else if ('error' in description) description = (description as any).error
    }

    notification[method]({
        key,
        className: 'simple-notification',
        message: undefined,
        description,
        duration,
    })
}

export const simpleFormErrorNotification = (error: any) => {
    const errors: string[] = []

    Object.keys(error).forEach((key) => {
        if (error[key].errors) {
            error[key].errors.forEach((err: any) => errors.push(err.message))
        }
    })

    simpleNotification(
        'error',
        errors.map((err: string) => <div key={err}>{err}</div>),
    )
}

export const titleCase = (str: string): string => {
    if (/^</.test(str)) {
        return str
    }

    return str
        .toLowerCase()
        .split(/[ _-]/)
        .map((word) => {
            return word.replace(word[0], word[0].toUpperCase())
        })
        .join(' ')
}

const doNotConvertKeys = [
    'filtersJson',
    'filters_json',
    'filters',
    'configuration',
    'utm_params',
    'utmParams',
    'evaluation_schedule',
    'evaluationSchedule',
    'display_meta',
    'displayMeta',
    'billingData',
    'billing_data',
    'addedNotificationQueryParams',
    'added_notification_query_params',
]

/**
 * deeply converts keys of an object from one case to another
 * @param oldObject {object | array | string} object to convert
 * @param converter {string} function to convert key.
 * @return converted object
 */
export const convertCase = (oldObject: any, converter: 'camel' | 'snake' | 'title'): any => {
    let newObject: any

    if (!oldObject || typeof oldObject !== 'object' || !Object.keys(oldObject).length) {
        return oldObject
    }

    if (Array.isArray(oldObject)) {
        newObject = oldObject.map((element) => convertCase(element, converter))
    } else {
        newObject = {}
        Object.keys(oldObject).forEach((oldKey) => {
            const newKey = changeCase[converter](oldKey)

            if (arrayContains(doNotConvertKeys, oldKey)) {
                newObject[newKey] = oldObject[oldKey]
            } else {
                newObject[newKey] = convertCase(oldObject[oldKey], converter)
            }
        })
    }

    return newObject
}

export const cleanNumericDisplays = (value: string | number, type: 'num' | 'pct' = 'num'): string => {
    value = value.toString()

    if (type === 'num') {
        value = value.replace(/\.[0]{1,2}?([A-Za-z]+)$/i, '$1')
    } else {
        value = value.replace(/\.[0]{1,2}?(%)$/i, '$1')
    }

    return value
}

export const shouldUseLegacyAuthHeader = (): boolean => {
    return /apple/i.test(navigator.vendor) && !location.hostname.includes(process.env.PLATFORM_ROOT_DOMAIN!)
}
