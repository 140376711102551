import { useCallback, useEffect } from 'react'
import { useDataViewContext } from './context'
import { IApiResponsePaginationLinks } from '../../interfaces/api-response-pagination-links'
import { DataSourceResponse } from './types'
import { useDataViewSnapshot } from './helpers'
import structuredClone from '@ungap/structured-clone'

type DataViewResourceLoaderProps<T extends {}> = {
    level: 'org' | 'domain'
} & (
    | {
          type: 'level'
          loader: (...args: any[]) => T | Promise<T>
          onLoaded?: (data: T) => void
      }
    | {
          type: 'dataSource'
          loader: (...args: any[]) => DataSourceResponse<T> | Promise<DataSourceResponse<T>>
          onLoaded?: (dataSource: T[]) => void
      }
)

export const DataViewResourceLoader = <T extends {} = any>(props: DataViewResourceLoaderProps<T>) => {
    const [state, dispatch] = useDataViewContext()
    const { type, onLoaded } = props

    const isDataSourceLoader = type === 'dataSource'

    const [snapshot, prevSnapshot] = useDataViewSnapshot({
        augments: { t: type },
        includeFilters: isDataSourceLoader,
        includeTable: isDataSourceLoader,
    })

    const loadingPropertyKey = type === 'level' ? 'levelResourceLoading' : 'dataSourceLoading'

    const loadResource = useCallback(async () => {
        if (type === 'level') {
            dispatch({ type: 'SET_LEVEL_RESOURCE_LOADING' })
        } else {
            dispatch({ type: 'SET_DATA_SOURCE_LOADING' })
        }

        let data: any
        let links: IApiResponsePaginationLinks | undefined
        let requestCancelled = false

        try {
            if (type === 'level') {
                data = await props.loader(structuredClone(state.filterValues))
            } else {
                const res = await props.loader(
                    structuredClone(state.filterValues),
                    state.table,
                    Array.from(state.dataSource),
                )

                data = res.data
                links = res.meta?.links
                requestCancelled = res.cancelled ?? requestCancelled

                if (res.error) {
                    console.error(res.error)
                } else if (!res.ok) {
                    console.debug(`[DataViewResourceLoader] Response Failure`, res)
                }
            }
        } catch (error) {
            console.warn(`[DataViewResourceLoader] ERROR: ${error}`)
        }

        if (!requestCancelled) {
            if (type === 'level') {
                dispatch({ type: 'SET_LEVEL', data })
            } else {
                dispatch({ type: 'SET_DATA_SOURCE', data: { data, links } })
            }

            onLoaded?.(data)
        }
    }, [snapshot, !!onLoaded])

    // ----- Snapshot change & refresh request detection -----
    useEffect(() => {
        const newSnapshotDetected = snapshot !== prevSnapshot
        const dataSourceRefreshRequested =
            type === 'dataSource' && (state.refreshRequested || state.autoRefreshRequested)

        if (newSnapshotDetected || (dataSourceRefreshRequested && !state[loadingPropertyKey])) {
            loadResource()
        }
    }, [snapshot, state[loadingPropertyKey], state.refreshRequested, state.autoRefreshRequested])
    // -----

    return null
}
