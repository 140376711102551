import React, { useCallback, useEffect, useState } from 'react'
import { AppMessageColumnViewProps, AppMessageDataRecord } from './types'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { AbilityAction } from '../../enums/ability-action.enum'
import { asCaslSubject } from '../../stores/app-ability'
import { IStatusTypeValue, StatusType } from '../../enums/status-type'
import { AsyncButton } from '../async-button/async-button.component'
import { useDataViewContext } from '../data-view/context'
import { AppMessageListType } from './enums'
import { useService } from '@pushly/aqe/lib/hooks'
import { AppState } from '../../stores/app'
import { AppService } from '../../services/index'
import { buildPrimaryLinkUrl, buildDuplicateUrl, buildEditUrl } from './helpers'
import { AppMessageScheduleService } from '../../services/app-message-schedule.service'
import MenuItem from 'antd/es/menu/MenuItem'

type AppMessageActionColumnViewProps = AppMessageColumnViewProps & {
    onArchiveClick(item: MenuItem): void
}

export const AppMessageActionColumnView = (props: AppMessageActionColumnViewProps) => {
    const [state, _dispatch, requestDataSourceReload] = useDataViewContext<AppMessageDataRecord>()
    const { type, data } = props

    const appState = useService(AppState)
    const appSvc = useService(AppService)
    const appMessageScheduleSvc = useService(AppMessageScheduleService)

    const isAppMessageDataView = type === AppMessageListType.APP_MESSAGE
    const isTemplateDataView = type === AppMessageListType.TEMPLATE

    let abilityEntity = SubjectEntity.APP_MESSAGE
    let abilityConstraint: any = {}
    abilityConstraint.domainId = state.levelResourceId

    const canMutate = appState.abilityStore.can(AbilityAction.UPDATE, asCaslSubject(abilityEntity, abilityConstraint))

    const [primaryLinkUrl, setPrimaryLinkUrl] = useState(buildPrimaryLinkUrl(state.level, data, appSvc.routeWithin))
    useEffect(() => {
        setPrimaryLinkUrl(buildPrimaryLinkUrl(state.level, data, appSvc.routeWithin))
    }, [type, state.level, state.levelResourceId, data.id])

    const [editUrl, setEditUrl] = useState(buildEditUrl(state.level, data, appSvc.routeWithin))
    useEffect(() => {
        setEditUrl(buildEditUrl(state.level, data, appSvc.routeWithin))
    }, [type, state.level, state.levelResourceId, data.id])

    const [duplicateUrl, setDuplicateUrl] = useState(buildDuplicateUrl(state.level, data, appSvc.routeWithin))
    useEffect(() => {
        setDuplicateUrl(buildDuplicateUrl(state.level, data, appSvc.routeWithin))
    }, [type, state.level, state.levelResourceId, data.id])

    const handleStatusChange = useCallback(
        async ({ name }: IStatusTypeValue) => {
            await appMessageScheduleSvc.updateAppMessageSchedule(state.levelResourceId, data.id!, { status: name })
            requestDataSourceReload()
        },
        [type, state.level, state.levelResourceId, data.id],
    )

    const isCancellable = true

    let actions: any = []

    if (!props.nativeDisabled && canMutate) {
        actions.push(
            {
                text: 'Edit',
                icon: 'edit',
                onClick: () => appSvc.route(editUrl),
                data,
                altHref: editUrl,
            },
            {
                text: 'Duplicate',
                icon: 'copy',
                onClick: () => appSvc.route(duplicateUrl),
                data,
                altHref: duplicateUrl,
            },
        )

        if (isAppMessageDataView && 'status' in data) {
            if (actions.length) {
                actions.push({ divider: true })
            }

            if (data.status === StatusType.ACTIVE.name) {
                actions.push({
                    text: 'Pause',
                    icon: 'pause-circle',
                    onClick: () => handleStatusChange(StatusType.PAUSED),
                    data,
                })
            } else if (data.status === StatusType.PAUSED.name) {
                actions.push({
                    text: 'Activate',
                    icon: 'play-circle',
                    onClick: () => handleStatusChange(StatusType.ACTIVE),
                    data,
                })
            }
        }
    }

    if (
        canMutate &&
        isCancellable &&
        isAppMessageDataView &&
        'status' in data &&
        [StatusType.ACTIVE.name, StatusType.PAUSED.name].includes(data.status)
    ) {
        if (actions.length) {
            actions.push({ divider: true })
        }

        actions.push({
            text: 'Cancel',
            icon: 'close',
            onClick: () => handleStatusChange(StatusType.CANCELLED),
            data,
        })
    }

    const canUserArchive =
        appState.currentUser?.isInternalUser === true ||
        appState.abilityStore.can(AbilityAction.UPDATE, appState.abilityStore.currentDomainIdentity)

    if (
        state.level === 'domain' &&
        canUserArchive &&
        (('status' in data && [StatusType.COMPLETED.name, StatusType.CANCELLED.name].includes(data.status)) ||
            isTemplateDataView)
    ) {
        actions.push({
            text: 'Archive',
            icon: 'folder',
            onClick: props.onArchiveClick,
            disabled: !isAppMessageDataView && props.nativeDisabled,
            data,
        })
    }

    if (actions.length === 0) {
        actions = undefined
    }

    return (
        <AsyncButton
            actions={actions}
            onClick={() => appSvc.route(primaryLinkUrl)}
            size="small"
            altHref={primaryLinkUrl}
            disabled={!isAppMessageDataView && props.nativeDisabled}
        >
            {isAppMessageDataView ? <span>View</span> : <span>Schedule App Message</span>}
        </AsyncButton>
    )
}
