import React from 'react'
import classnames from 'classnames'
import { DataViewRefreshProps } from './types'
import { Button, Select, SelectProps, Switch, Tooltip } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'
import { useDataViewContext } from './context'
import { getQueryStringParam } from '../../_utils/get-query-string'
import { SwitchChangeEventHandler } from 'antd/lib/switch'
import { RefreshTimeout } from '../../enums/refresh-timeout'

const REFRESH_OPTS_QS_PARAM = '_ropts'

export const DataViewRefresh = (props: DataViewRefreshProps) => {
    const [state, dispatch] = useDataViewContext()

    let showRefreshOptions: boolean | undefined
    const showRefreshOptsParams = getQueryStringParam(REFRESH_OPTS_QS_PARAM) ?? false
    if (showRefreshOptsParams !== undefined && (showRefreshOptsParams === 'true' || showRefreshOptsParams === '1')) {
        showRefreshOptions = true
    }

    const hideAutoRefreshOptions = showRefreshOptions === undefined ? props.hideAutoRefreshOptions : !showRefreshOptions
    const hideAllRefresh = props.hideRefresh && props.hideAutoRefreshOptions

    const activeRefreshRequestExists = state.refreshRequested || state.autoRefreshRequested
    const loaderAnimationIsActive = state.loading || state.dataSourceLoading || state.refreshing

    const onRefreshEnabledChange: SwitchChangeEventHandler = (checked, _ev) => {
        props.onRefreshEnabledChange?.(checked)
    }

    const onRefreshTimeoutChange: SelectProps<RefreshTimeout>['onChange'] = (value, _option) => {
        props.onRefreshTimeoutChange?.(value)
    }

    return (
        <div
            className={classnames('data-view-refresh', {
                hidden: hideAllRefresh,
            })}
        >
            {!hideAutoRefreshOptions && (
                <>
                    <Switch size="small" checked={state.refreshEnabled} onChange={onRefreshEnabledChange} />
                    <span>Auto-Refresh</span>

                    <Select
                        className={classnames('data-view-timeout-select')}
                        size="small"
                        disabled={!state.refreshEnabled || state.levelResourceLoading}
                        options={RefreshTimeout.getAll().map((t) => ({
                            value: t,
                            label: RefreshTimeout.getLabel(t),
                        }))}
                        value={state.refreshTimeout}
                        onChange={onRefreshTimeoutChange}
                    />
                </>
            )}

            {!props.hideRefresh && (
                <Tooltip title="Refresh">
                    <Button
                        className={classnames('data-view-refresh-toggle')}
                        size="small"
                        shape="round"
                        onClick={() => {
                            if (!state.dataSourceLoading && !activeRefreshRequestExists) {
                                dispatch({ type: 'SET_REFRESH_REQUESTED' })
                                props.onRefreshClick?.()
                            }
                        }}
                        disabled={!state.refreshEnabled || state.levelResourceLoading}
                    >
                        <ReloadOutlined spin={loaderAnimationIsActive} />
                    </Button>
                </Tooltip>
            )}
        </div>
    )
}
