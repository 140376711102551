import React from 'react'
import classnames from 'classnames'
import { BaseDataViewFilterProps, DataViewFiltersProps } from './types'
import { DataViewFilter } from './data-view-filter'
import { useDataViewContext } from './context'

const meetsCondition = (filter: BaseDataViewFilterProps): boolean => {
    return filter.condition?.() ?? true
}

export const DataViewFilters = (props: DataViewFiltersProps) => {
    const [state] = useDataViewContext()
    const filters = props.filters ?? []

    return (
        <div
            className={classnames('data-view-filters', {
                hidden: !filters.length,
            })}
        >
            {props.filtersAddonBefore}

            {filters.filter(meetsCondition).map((filter) => (
                <DataViewFilter
                    {...filter}
                    key={filter.key}
                    name={filter.key}
                    currentValue={state.filterValues[filter.key]}
                />
            ))}

            {props.filtersAddonAfter}
        </div>
    )
}
