import React from 'react'
import { AppMessageColumnViewProps, AppMessageDataRecord } from './types'
import { useDataViewContext } from '../data-view/context'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { TableRowEntityDisplay } from '../table-row-entity-display/table-row-entity-display'
import { NoTranslate } from '../no-translate/no-translate'
import { NativeIosBadge } from '../badges/native-ios-badge'
import { NativeAndroidBadge } from '../badges/native-android-badge'
import { buildPrimaryLinkUrl } from './helpers'
import { useService } from '@pushly/aqe/lib/hooks/index'
import { AppService } from '../../services/index'
import { AppMessageUiTemplate } from '@pushly/models/lib/structs/app-messages/app-message-ui-template'

export const AppMessageTemplateColumnView = (props: AppMessageColumnViewProps<AppMessageUiTemplate>) => {
    const [state] = useDataViewContext<AppMessageDataRecord>()
    const { type, data } = props
    const { name, description, channels } = data

    const appSvc = useService(AppService)

    const LinkWrapper = () =>
        props.nativeDisabled ? (
            <a href={buildPrimaryLinkUrl(state.level, data, appSvc.routeWithin)}>{name}</a>
        ) : (
            <span>{name}</span>
        )

    return (
        <TableRowEntityDisplay
            title={
                <NoTranslate>
                    <LinkWrapper />
                </NoTranslate>
            }
            description={<NoTranslate>{description}</NoTranslate>}
            badges={
                <>
                    {channels?.includes(DeliveryChannel.NATIVE_IOS) && <NativeIosBadge />}
                    {channels?.includes(DeliveryChannel.NATIVE_ANDROID) && <NativeAndroidBadge />}
                </>
            }
        />
    )
}
