import { DomainDto } from '../dtos/domain'
import {
    DEFAULT_CLOSE_LABEL,
    DEFAULT_OPEN_URL_LABEL,
    INotificationAction,
    NotificationActionType,
} from '../interfaces/notification-action'
import * as clone from 'clone'
import * as isValidDomain from 'is-valid-domain'
import * as isValidHostname from 'is-valid-hostname'
import { observe } from 'mobx'
import { AppState } from '../stores/app'
import * as randomstring from 'randomstring'
import { SegmentService } from '../services/segment'
import { Container } from 'typescript-ioc/es5'
import { DomainService } from '../services'
import { fetchDomain, getCurrentDomainSession, getCurrentDomainStorage, setCurrentDomainSession } from './utils'
import { Error404 } from '../exceptions/error-404'
import { getPathEntityId } from './get-path-entity-id'
import IApiCallOptions from '../interfaces/api-call-options'
import { AppActionContext } from '../enums/app-action-context'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { Domain } from '@pushly/models/lib/structs/domain'
import { DomainBuilder } from '@pushly/models/lib/builders/domain-builder'
import { SegmentType } from '@pushly/models/lib/enums/segment-type'

export const ifDomainChanged = (appState: AppState, cb: () => void) => {
    return observe(appState, 'currentDomainJsonData', ({ oldValue, newValue }: any) => {
        try {
            if (JSON.parse(oldValue)?.id !== JSON.parse(newValue)?.id) {
                cb()
            }
        } catch (_) {}
    })
}

export const onDomainIdChange = (
    appState: AppState,
    cb: (newDomain: DomainDto, oldDomain: DomainDto | undefined) => void,
) => {
    return observe(appState, 'currentDomainJsonData', ({ oldValue, newValue }: any) => {
        try {
            const oldData = !oldValue ? undefined : DomainDto.fromApiResponse(JSON.parse(oldValue))
            const newData = DomainDto.fromApiResponse(JSON.parse(newValue))

            if (newData.id.toString() !== oldData?.id?.toString()) {
                // 50ms handles route rewrite
                setTimeout(() => cb(newData, oldData), 50)
            }
        } catch (_) {
            // unable to parse domain json - do not run callback
        }
    })
}

export function getDomainCurrencyCode(domain?: DomainDto): string {
    return domain?.currencyCode ?? 'USD'
}

export function getDomainCurrencySymbol(domain?: DomainDto): string {
    const currency = getDomainCurrencyCode(domain)
    return (
        Intl.NumberFormat('en-us', { style: 'currency', currency })
            .formatToParts(0)
            .find((p) => p.type === 'currency')?.value ?? '$'
    )
}

export function getDomainDefaultActionLabels(domain?: DomainDto): {
    openUrl: string
    close: string
} {
    let defaultOpenUrlLabel = DEFAULT_OPEN_URL_LABEL
    let defaultCloseUrlLabel = DEFAULT_CLOSE_LABEL

    if (!!domain && !!domain.defaultNotificationActionLabels) {
        defaultOpenUrlLabel = domain.defaultNotificationActionLabels.openUrl || DEFAULT_OPEN_URL_LABEL
        defaultCloseUrlLabel = domain.defaultNotificationActionLabels.close || DEFAULT_CLOSE_LABEL
    }

    return {
        openUrl: defaultOpenUrlLabel,
        close: defaultCloseUrlLabel,
    }
}

export function prepareWhitelistDomains(domains: string[], allowSubdomainWildcards?: boolean): string[] {
    domains = domains ?? []

    let testDomains = clone(domains)
    if (allowSubdomainWildcards) {
        testDomains = testDomains.map((domainStr) => domainStr.replace(/^\*\./, ''))
    }

    if (Array.isArray(testDomains) && testDomains.length > 0) {
        const invalidDomainMatch = testDomains.some(
            (domainStr: string) => !(isValidHostname(domainStr) || isValidDomain(domainStr)),
        )

        if (invalidDomainMatch) {
            throw {
                whitelistDomains: {
                    errors: [
                        {
                            message:
                                'Whitelist domains must be valid domain names or IP addresses containing no special characters.',
                        },
                    ],
                },
            }
        }
    }

    return domains
}

export const getDefaultNotificationActions = (domain: DomainDto | null): INotificationAction[] => {
    const defaultActions: any[] = clone(domain?.defaultNotificationActions ?? [])
    const compiledDefaultActions = defaultActions.map((action) => ({
        ordinal: action.ordinal,
        type: action.type,
        label: action.label,
        usePrimaryLandingUrl: action.use_primary_landing_url ?? action.usePrimaryLandingUrl,
        landingUrl: action.landing_url ?? action.landingUrl,
    }))

    if (compiledDefaultActions.length === 0) {
        const defaultLabels = domain?.defaultNotificationActionLabels
        const openLabel = defaultLabels?.openUrl ?? DEFAULT_OPEN_URL_LABEL

        compiledDefaultActions.push({
            ordinal: 0,
            type: NotificationActionType.OPEN_URL,
            label: openLabel,
            usePrimaryLandingUrl: true,
            landingUrl: undefined,
        })
    }

    return compiledDefaultActions
}

export const parseDefaultNotificationAction = (
    domain: DomainDto | null,
    ordinal: number,
    actionsOverride?: any[],
): INotificationAction | undefined => {
    const defaultActions: any[] = actionsOverride ?? domain?.defaultNotificationActions ?? []
    const defaultOpenUrlLabel = domain?.defaultNotificationActionLabels?.openUrl ?? DEFAULT_OPEN_URL_LABEL

    let action = defaultActions.find((a) => a.ordinal === ordinal)
    if (!!action) {
        action.usePrimaryLandingUrl = action.usePrimaryLandingUrl ?? action.use_primary_landing_url
        delete action.use_primary_landing_url

        action.landingUrl = action.landingUrl ?? action.landing_url
        delete action.landing_url
    }

    if (ordinal === 0 && !action) {
        action = {
            ordinal: 0,
            type: NotificationActionType.OPEN_URL,
            label: defaultOpenUrlLabel,
            usePrimaryLandingUrl: true,
        }
    }

    if (action) {
        action.displayMeta = {
            eid: randomstring.generate(),
        }
    }

    return clone(action)
}

export const getDomainKeywords = async (domainId: number, queryOpts: any = { pagination: 0 }) => {
    const svc = Container.get(DomainService)
    const { ok, data } = await svc.fetchKeywordsByDomainId(domainId, { query: queryOpts })
    return !ok ? [] : data.map(({ name }) => ({ value: name, label: name }))
}

export const getCustomMacroFields = async (domainId: number) => {
    const svc = Container.get(SegmentService)
    const { ok, data } = await svc.fetchSegmentFieldsByDomainId(domainId)
    return !ok
        ? []
        : data.map(({ key, name }: { key: string; name: string }) => ({
              value: key.replace(`.${domainId}_`, '.'),
              label: name,
          }))
}

export const getDefaultSegment = async (domainId: number, opts: IApiCallOptions = {}) => {
    const svc = Container.get(DomainService)
    const { ok, data } = await svc.fetchSegmentsByDomainId(domainId, { ...opts, query: { isDefault: true } })
    return !ok ? undefined : data[0]
}

export interface ISynchronizedDomainStateResponse {
    domain?: DomainDto
    error?: Error
    context: AppActionContext
    domainNotInRequestedOrg: boolean
    reqIds: {
        domain?: number
        org?: number
    }
}
export async function synchronizeDomainStates(): Promise<ISynchronizedDomainStateResponse> {
    const res: ISynchronizedDomainStateResponse = {
        context: AppActionContext.DOMAIN,
        domainNotInRequestedOrg: false,
        reqIds: {},
    }

    const coldStorageDomain = getCurrentDomainStorage()
    const sessionDomain = getCurrentDomainSession()
    const pathRequestedDomainId = (res.reqIds.domain = getPathEntityId('domain'))
    const pathRequestedOrgId = (res.reqIds.org = getPathEntityId('organization'))

    const currentlyShowingId = sessionDomain?.id ?? coldStorageDomain?.id ?? undefined

    let pathRequestedDomain: DomainDto | undefined
    if (pathRequestedDomainId) {
        if (pathRequestedDomainId.toString() !== currentlyShowingId?.toString()) {
            // the requested domain is not currenly loaded
            // onto the client - attempt to reload from remote
            try {
                pathRequestedDomain = await fetchDomain(pathRequestedDomainId)
            } catch {
                res.error = new Error404()
            }
        }
    } else if (pathRequestedOrgId) {
        res.context = AppActionContext.ORG

        // determine if the current session/coldStorage domain
        // is within the requested org scope
        const prevDomain = sessionDomain ?? coldStorageDomain
        if (prevDomain && prevDomain.accountId.toString() !== pathRequestedOrgId.toString()) {
            res.domainNotInRequestedOrg = true
        }
    }

    // repopulate session from resolved domain
    if (!sessionDomain && !!(pathRequestedDomain ?? coldStorageDomain)) {
        setCurrentDomainSession((pathRequestedDomain ?? coldStorageDomain)!)
    }

    res.domain = pathRequestedDomain ?? sessionDomain ?? coldStorageDomain

    return res
}

/**
 * TODO: DomainDto.is<Channel>Enabled(), etc...
 */

export type DomainChannelConfig = Pick<
    DomainDto | Domain | DomainBuilder,
    'flags' | 'nativeApnsConfiguration' | 'nativeFcmConfiguration'
>

export function isDeliveryChannelEnabled(domain: DomainChannelConfig, channel: DeliveryChannel): boolean
export function isDeliveryChannelEnabled(
    domain: DomainChannelConfig,
    channel: DeliveryChannel,
    andIsActive: boolean,
): boolean
export function isDeliveryChannelEnabled(
    domain: DomainChannelConfig,
    channel: DeliveryChannel,
    andIsActive: boolean = false,
): boolean {
    const isEnabled = domain?.flags?.includes(DeliveryChannel.getAssociatedFlag(channel)!) ?? false

    if (!andIsActive) {
        return isEnabled
    }

    const domainChannelPropMap = {
        [DeliveryChannel.WEB]: {
            isActive: domain.flags?.includes(DeliveryChannel.getAssociatedFlag(DeliveryChannel.WEB)!) ?? false,
        },
        [DeliveryChannel.NATIVE_IOS]: domain.nativeApnsConfiguration,
        [DeliveryChannel.NATIVE_ANDROID]: domain.nativeFcmConfiguration,
    }

    return domainChannelPropMap[channel]?.isActive ?? false
}

/**
 * TODO: should replace all usage of "initialChannels" using DomainDto.is<Channel>Enabled(), etc...
 */
export function getEnabledDeliveryChannels(domain: DomainChannelConfig): DeliveryChannel[]
export function getEnabledDeliveryChannels(domain: DomainChannelConfig, activeOnly: boolean): DeliveryChannel[]
export function getEnabledDeliveryChannels(
    domain: DomainChannelConfig,
    activeOnly: boolean = false,
): DeliveryChannel[] {
    return DeliveryChannel.getAllChannels()
        .map<[DeliveryChannel, boolean]>((ch) => [ch, isDeliveryChannelEnabled(domain, ch, activeOnly)])
        .filter(([, enabled]) => enabled)
        .map(([ch]) => ch)
}

export function getAvailableSegmentTypes(domain: DomainDto | Domain | DomainBuilder): SegmentType[] {
    const domainChannels = getEnabledDeliveryChannels(domain, true)
    const allowedSegmentTypes = [SegmentType.STANDARD, SegmentType.ALL_PUSH_SUBSCRIBERS]

    if (
        domainChannels.includes(DeliveryChannel.NATIVE_ANDROID) ||
        domainChannels.includes(DeliveryChannel.NATIVE_IOS)
    ) {
        allowedSegmentTypes.push(SegmentType.NATIVE_APP_PUSH_SUBSCRIBERS)
        allowedSegmentTypes.push(SegmentType.ALL_APP_USERS)
    }

    if (domainChannels.includes(DeliveryChannel.WEB)) {
        allowedSegmentTypes.push(SegmentType.WEB_PUSH_SUBSCRIBERS)
    }

    return allowedSegmentTypes
}
